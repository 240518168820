import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Contexthandlerscontext } from '../../../Contexthandlerscontext.js';
import { LanguageContext } from '../../../LanguageContext';
import generalstyles from '../Generalfiles/CSS_GENERAL/general.module.css';
import homestyles from './home.module.css';
// Icons
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';

import { NotificationManager } from 'react-notifications';
import { useMutation } from 'react-query';
import { AiOutlineBarChart } from '@react-icons/all-files/ai/AiOutlineBarChart';
import { BsArrowLeftShort } from '@react-icons/all-files/bs/BsArrowLeftShort';
import { BsArrowRightShort } from '@react-icons/all-files/bs/BsArrowRightShort';
import { AiOutlineWhatsApp } from '@react-icons/all-files/ai/AiOutlineWhatsApp';
import { BsCreditCard } from '@react-icons/all-files/bs/BsCreditCard';
import { MdGTranslate } from '@react-icons/all-files/md/MdGTranslate';
import { FaClipboardCheck } from '@react-icons/all-files/fa/FaClipboardCheck';
import { SiGoogleanalytics } from '@react-icons/all-files/si/SiGoogleanalytics';
// import { TextareaAutosize } from '@mui/material';
import TextareaAutosize from 'react-textarea-autosize';
import Select, { components } from 'react-select';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { sendlead_API } from '../../API/Login_API';
// Assets

import { Loggedincontext } from '../../../Loggedincontext.js';

import formstyles from './form.module.css';
import { defaultstyles, defaultstyles1, defaultstyles2 } from '../Generalfiles/selectstyles.js';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const Home = () => {
    const queryParameters = new URLSearchParams(window.location.search);

    let history = useHistory();
    const { setpageactive_context } = useContext(Contexthandlerscontext);
    const { serverbaselinkimgcontext } = useContext(Loggedincontext);
    const { lang, langdetect } = useContext(LanguageContext);
    const [alertattr, setalertattr] = useState([]);
    const [images, setimages] = useState([]);
    const [contactusmessagesent, setcontactusmessagesent] = useState(false);
    const [payload, setpayload] = useState({
        name: '',
        phonenumber: '',
        industry: '',
        companyname: '',
        jobtitle: '',
        package: '',
        companysize: '',
    });
    const submittabexwebsite_contactusformMutation = useMutation('submittabexwebsite_contactusformMutation_API', {
        mutationFn: sendlead_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('Error', 'Error ');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                if (data?.data?.sendfbpixelevent) {
                    var platformads = queryParameters.get('plat');
                    // if (platformads == 'google') {
                    // } else {
                    //     // window.fbq('track', 'Lead', { name: payload.name });

                    // }
                    window.fbq('track', 'Lead', { name: payload.name });
                    // if (platformads == 'facebook') {
                    // } else if (platformads == 'tiktok') {
                    //     window.ttq.track('SubmitForm', {});
                    // } else if (platformads == 'google') {
                    //     window.gtag('event', 'conversion', { send_to: 'AW-11338173696/1twgCK6th-IYEICau54q' });
                    // }
                }
                setcontactusmessagesent(true);
                NotificationManager.success('Message Sent');
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });

    useEffect(() => {
        document.title = 'Tabex Series';
    }, []);

    const [attr, setattr] = useState([]);
    const [packages, setpackages] = useState([]);
    useEffect(() => {
        setpageactive_context('/home');

        setattr([
            { name: lang.name, attr: 'name', type: 'text' },
            { name: lang.phonenumber, attr: 'phonenumber', type: 'number' },
            { name: lang.companyname, attr: 'companyname', type: 'text' },
            { name: lang.jobtitle, attr: 'jobtitle', type: 'text' },
        ]);
        setimages([
            serverbaselinkimgcontext + '/tr:w-600,h-400' + '/tabexserieswebsiteasset/1.png',
            serverbaselinkimgcontext + '/tr:w-600,h-400' + '/tabexserieswebsiteasset/2.png',
            serverbaselinkimgcontext + '/tr:w-600,h-400' + '/tabexserieswebsiteasset/3.png',
            serverbaselinkimgcontext + '/tr:w-600,h-400' + '/tabexserieswebsiteasset/4.png',
        ]);
        setpackages([
            {
                name: '3 ' + lang.months,
                namear: '3 ' + lang.months,
                description: lang.offerforlimitedtime,
                id: 0,
                pricepermonth: '3,900',
                priceperquarter: '4,500',
                priceperyear: 4400,
                image: serverbaselinkimgcontext + '/tabexserieswebsiteasset/pricing1.png',
            },
            {
                name: '6 ' + lang.months,
                namear: '6 ' + lang.months,
                description: lang.offerforlimitedtime,
                id: 1,
                pricepermonth: '7,200',
                priceperquarter: '9,000',
                priceperyear: 10000,
                image: serverbaselinkimgcontext + '/tabexserieswebsiteasset/pricing2.png',
            },
            {
                name: '12 ' + lang.month,
                namear: '12 ' + lang.month,
                description: lang.offerforlimitedtime,
                id: 2,
                pricepermonth: '12,600',
                priceperquarter: '18,000',
                priceperyear: 16000,
                image: serverbaselinkimgcontext + '/tabexserieswebsiteasset/pricing3.png',
            },
            // { name: lang.Platinum, id: 3, pricepermonth: 2000, priceperquarter: 5500, priceperyear: 21000, image: serverbaselinkimgcontext + '/tabexserieswebsiteasset/pricingasset.png' },
        ]);
    }, [langdetect, serverbaselinkimgcontext]);

    const Sectionone = () => {
        return (
            <section class="col-lg-12 p-0 d-flex p-md-0 pb-5 pl-md-4 pr-md-4" style={{ zIndex: 2, overflow: 'hidden' }}>
                <div class="row m-0 w-100 d-flex justify-content-center mt-5 mt-sm-2 pb-5">
                    {/* {langdetect == 'ar' && (
                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/price.png'} class={`${homestyles.pricebubble_ar}`} data-aos="fade-up" data-aos-easing="ease-in-out" />
                    )}
                    {langdetect == 'en' && (
                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/priceen.png'} class={`${homestyles.pricebubble_en}`} data-aos="fade-up" data-aos-easing="ease-in-out" />
                    )} */}
                    <div class="col-lg-12 pt-5 p-md-0 mt-5">
                        <div class="row m-0 w-100 p-md-0 d-flex justify-content-center">
                            <div class="col-lg-12 p-0 m-0 w-100 allcentered mb-3">
                                <p class="text-center m-0" style={{ fontWeight: langdetect == 'en' ? '' : 700 }}>
                                    <span class={homestyles.home_introtext + ' m-0 text-center '}>{lang.intro}</span>
                                    <br />
                                    <br />

                                    <span style={{ fontSize: '19px' }} class={' text-light m-0 text-center  '}>
                                        {lang.intro2} {lang.intro3}
                                    </span>
                                    <br />
                                    <br />

                                    <span style={{ fontSize: '24px', textDecoration: 'underline' }} class={' text-info m-0 text-center  '}>
                                        {lang.inlessthanaweek}
                                    </span>
                                </p>
                            </div>
                            <div class="col-lg-12 d-flex  justify-content-center mt-4 mb-3">
                                <button
                                    onClick={() => {
                                        // history.push('/home/signup');
                                        document.querySelector(`#Form`).scrollIntoView({ behavior: 'smooth' });
                                    }}
                                    class={`${homestyles.generalhomebutton}` + ' bg-primary bg-secondaryhover text-white d-flex justify-content-center align-items-center '}
                                    style={{ fontSize: langdetect == 'en' ? '14px' : '16px', width: langdetect == 'en' ? '240px' : '230px' }}
                                >
                                    {lang.getstartednow}
                                    {langdetect == 'en' && (
                                        <BsArrowRightShort
                                            size={20}
                                            style={{
                                                marginLeft: '5px',
                                            }}
                                        />
                                    )}
                                    {langdetect == 'ar' && (
                                        <BsArrowLeftShort
                                            size={20}
                                            style={{
                                                marginRight: '5px',
                                            }}
                                        />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class={' col-lg-6 d-flex justify-content-end mt-5'}>
                        <div class="col-lg-12 d-flex align-items-center justify-content-center mb-auto">
                            <img
                                src={serverbaselinkimgcontext + '/tr:w-600,h-400' + '/tabexserieswebsiteasset/introbg.png'}
                                style={{ width: '70%', height: '70%', objectFit: 'contain' }}
                                class="d-flex d-sm-none"
                            />
                            <img
                                src={serverbaselinkimgcontext + '/tr:w-600,h-400' + '/tabexserieswebsiteasset/introbg.png'}
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                class="d-none d-sm-flex"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    };
    const Sectiontwo = () => {
        return (
            <section class="col-lg-12 p-0 d-flex p-md-0 pb-5" style={{ zIndex: 2 }}>
                <div class="row m-0 w-100 d-flex justify-content-center mt-5 mt-sm-2 pb-5">
                    <div class="col-lg-12 pt-5 p-md-0 mt-5" data-aos="fade-up" data-aos-easing="ease-in-out">
                        <div class="row m-0 w-100 p-md-0 d-flex justify-content-center">
                            <div class="col-lg-12 p-0 m-0 w-100 allcentered">
                                <p class={homestyles.home_introtext + ' m-0 text-center '} style={{ fontWeight: langdetect == 'en' ? '' : 700, lineHeight: '50px', fontSize: '27px' }}>
                                    {lang.morethanthousandcustomerstrustus}
                                    <br />
                                    {lang.foreaseofusevarietyandcustomerserviceavailablehoursaday}
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
    // const Sectiontwo = () => {
    //     return (
    //         <section class={' col-lg-12 pl-5 pr-5 pl-md-0 pr-md-0'} style={{ zIndex: 2 }}>
    //             <div class="row m-0 w-100 pl-5 pr-5 pt-5 pl-md-0 pr-md-0">
    //                 <div class={'col-lg-6'} data-aos="fade-left" data-aos-easing="ease-in-out">
    //                     <div class="row m-0 w-100 d-flex justify-content-start p-md-0">
    //                         <div
    //                             class={generalstyles.sectionTitle + ' col-lg-12 p-0 mb-2 d-flex justify-content-start justify-content-md-center '}
    //                             style={{ fontWeight: langdetect == 'en' ? '' : 700 }}
    //                         >
    //                             <p class="m-0 p-0 wordbreak">{lang.buildyouronlinestore}</p>
    //                         </div>
    //                         <div class="col-lg-12 p-0 d-flex justify-content-start">
    //                             <p class="m-0 p-0 text-light text-md-center wordbreak font-18 poppinsitalic">{lang.buildinfoursteps}</p>
    //                         </div>
    //                         <div class="col-lg-12 ml-auto mr-auto p-0 d-flex justify-content-start mt-3">
    //                             <div class="row m-0 w-100 d-flex justify-content-start">
    //                                 <div class="col-lg-6 d-flex align-items-center justify-content-start mb-4">
    //                                     <div style={{ width: '5vh', height: '5vh', borderRadius: '50%', background: '#e6f7ff' }}>
    //                                         <i class="h-100 allcentered">
    //                                             <IoCheckmarkDoneOutline class="text-info" size={20} />
    //                                         </i>
    //                                     </div>
    //                                     <p class="m-0 p-0 ml-2 mr-2 poppinslight wordbreak font-18 text-info">{lang.buildstepone}</p>
    //                                 </div>
    //                                 <div class="col-lg-6 d-flex align-items-center mb-4">
    //                                     <div style={{ width: '5vh', height: '5vh', borderRadius: '50%', background: '#e6f7ff' }}>
    //                                         <i class="h-100 allcentered">
    //                                             <IoCheckmarkDoneOutline class="text-info" size={20} />
    //                                         </i>
    //                                     </div>
    //                                     <p class="m-0 p-0 ml-2 mr-2 poppinslight wordbreak font-18 text-info">{lang.buildsteptwo}</p>
    //                                 </div>
    //                                 <div class="col-lg-6 d-flex align-items-center mb-2 mb-md-4">
    //                                     <div style={{ width: '5vh', height: '5vh', borderRadius: '50%', background: '#e6f7ff' }}>
    //                                         <i class="h-100 allcentered">
    //                                             <IoCheckmarkDoneOutline class="text-info" size={20} />
    //                                         </i>
    //                                     </div>
    //                                     <p class="m-0 p-0 ml-2 mr-2 poppinslight wordbreak font-18 text-info">{lang.buildstepthree}</p>
    //                                 </div>
    //                                 <div class="col-lg-6 d-flex align-items-center mb-2">
    //                                     <div style={{ width: '5vh', height: '5vh', borderRadius: '50%', background: '#e6f7ff' }}>
    //                                         <i class="h-100 allcentered">
    //                                             <IoCheckmarkDoneOutline class="text-info" size={20} />
    //                                         </i>
    //                                     </div>
    //                                     <p class="m-0 p-0 ml-2 mr-2 poppinslight wordbreak font-18 text-info">{lang.buildstepfour}</p>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-lg-12 d-flex justify-content-start justify-content-md-center mt-4 mb-4">
    //                             <button
    //                                 onClick={() => {
    //                                     history.push('/home/pricing');
    //                                 }}
    //                                 class={`${homestyles.generalhomebutton}` + ' bg-primary bg-secondaryhover text-white d-flex justify-content-center align-items-center w-sm-100 '}
    //                                 style={{ width: langdetect == 'en' ? '250px' : '230px' }}
    //                             >
    //                                 {lang.createyouronlinestorenow}
    //                                 {langdetect == 'en' && (
    //                                     <BsArrowRightShort
    //                                         size={20}
    //                                         style={{
    //                                             marginLeft: '5px',
    //                                         }}
    //                                     />
    //                                 )}
    //                                 {langdetect == 'ar' && (
    //                                     <BsArrowLeftShort
    //                                         size={20}
    //                                         style={{
    //                                             marginRight: '5px',
    //                                         }}
    //                                     />
    //                                 )}
    //                             </button>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div class="col-lg-6 col-md-12 d-flex justify-content-end justify-content-md-center" data-aos="fade-right" data-aos-easing="ease-in-out">
    //                     <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/features.png'} style={{ maxWidth: '100%', maxHeight: '70%' }} />
    //                 </div>
    //             </div>
    //         </section>
    //     );
    // };
    const Sectionthree = () => {
        return (
            <section class={' col-lg-12 p-0 mb-5 pt-5 pl-5 pr-5 pl-md-3 pr-md-3 '} style={{ background: 'rgba(242, 242, 242, .3)', zIndex: 2 }}>
                <div class="row m-0 w-100 mb-5 d-flex justify-content-center">
                    <div class="col-lg-12 d-flex align-items-center justify-content-center mb-5" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <p class={generalstyles.sectionTitle + ' p-0 m-0 text-center wordbreak '} style={{ fontWeight: langdetect == 'en' ? '' : 700 }}>
                            {lang.geteverythingyouneed} <br />
                            {lang.togrowupyourbusiness}
                        </p>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex align-items-stretch justify-content-center mb-5" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <div class={`${generalstyles.card}` + ' w-100 p-4 '}>
                            <div class="row m-0 w-100">
                                <div class="col-lg-12 allcentered mb-3">
                                    <div class="allcentered mb-3" style={{ width: '80px', height: '80px' }}>
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/mobileappicon.png'} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                                    </div>
                                </div>
                                <div class="col-lg-12 allcentered mb-3">
                                    <p class="m-0 p-0 font-18 text-center wordbreak poppinssemibold" style={{ fontWeight: langdetect == 'en' ? '' : 600 }}>
                                        {lang.createyourmobileapp}
                                    </p>
                                </div>
                                <div class="col-lg-12 allcentered">
                                    <p class="m-0 p-0 text-light text-center poppinslight" style={{ fontSize: langdetect == 'en' ? '15px' : '17px' }}>
                                        {lang.createyourmobileappdescription}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-1 d-flex align-items-stretch justify-content-center mb-5 p-0 d-md-none" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <img
                            src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/arrow.png'}
                            style={{ maxWidth: '100%', maxHeight: '100%', transform: langdetect == 'en' ? 'scale(1)' : 'scale(-1)', objectFit: 'contain' }}
                        />
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex align-items-stretch justify-content-center mb-5" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <div class={`${generalstyles.card}` + ' w-100 p-4 '}>
                            <div class="row m-0 w-100">
                                <div class="col-lg-12 allcentered mb-3">
                                    <div class="allcentered mb-2" style={{ width: '80px', height: '80px' }}>
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/websiteicon.png'} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                                    </div>
                                </div>
                                <div class="col-lg-12 allcentered mb-3">
                                    <p class="m-0 p-0 poppinssemibold wordbreak font-18" style={{ fontWeight: langdetect == 'en' ? '' : 600 }}>
                                        {lang.createyouronlinestore}
                                    </p>
                                </div>
                                <div class="col-lg-12 allcentered">
                                    <p class="m-0 p-0 text-light text-center poppinslight wordbreak" style={{ fontSize: langdetect == 'en' ? '15px' : '17px' }}>
                                        {lang.createyouronlinestoredescription}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-1 d-flex align-items-stretch justify-content-center mb-5 p-0 d-md-none" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <img
                            src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/arrow.png'}
                            style={{ maxWidth: '100%', maxHeight: '100%', transform: langdetect == 'en' ? 'scale(1)' : 'scale(-1)', objectFit: 'contain' }}
                        />
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex align-items-stretch justify-content-center mb-5" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <div class={`${generalstyles.card}` + ' w-100 p-4 '}>
                            <div class="row m-0 w-100">
                                <div class="col-lg-12 allcentered mb-3">
                                    <div class="allcentered mb-3" style={{ width: '60px', height: '60px', objectFit: 'contain' }}>
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/editoricon2.png'} />
                                    </div>
                                </div>
                                <div class="col-lg-12 allcentered mb-3">
                                    <p class="m-0 p-0 poppinssemibold font-18" style={{ fontWeight: langdetect == 'en' ? '' : 600 }}>
                                        {lang.customizeit}
                                    </p>
                                </div>
                                <div class="col-lg-12 allcentered">
                                    <p class="m-0 p-0 text-light text-center poppinslight wordbreak" style={{ fontSize: langdetect == 'en' ? '15px' : '17px' }}>
                                        {lang.customizeitdescription}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex align-items-stretch justify-content-center mb-5" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <div class={`${generalstyles.card}` + ' w-100 p-4 '}>
                            <div class="row m-0 w-100">
                                <div class="col-lg-12 allcentered mb-3">
                                    <div class="allcentered mb-3" style={{ width: '60px', height: '60px', objectFit: 'contain' }}>
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/dashboardicon.png'} />
                                    </div>
                                </div>
                                <div class="col-lg-12 allcentered mb-3">
                                    <p class="m-0 p-0 poppinssemibold font-18" style={{ fontWeight: langdetect == 'en' ? '' : 600 }}>
                                        {lang.yourowndashboard}
                                    </p>
                                </div>
                                <div class="col-lg-12 allcentered">
                                    <p class="m-0 p-0 text-light text-center poppinslight" style={{ fontSize: langdetect == 'en' ? '15px' : '17px' }}>
                                        {lang.yourowndashboarddescription}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 d-flex align-items-stretch justify-content-center mb-5 p-0 d-md-none" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <img
                            src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/arrow.png'}
                            style={{ maxWidth: '100%', maxHeight: '100%', transform: langdetect == 'en' ? 'scale(1)' : 'scale(-1)', objectFit: 'contain' }}
                        />
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex align-items-stretch justify-content-center mb-5" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <div class={`${generalstyles.card}` + ' w-100 p-4 '}>
                            <div class="row m-0 w-100">
                                <div class="col-lg-12 allcentered mb-3">
                                    <div class="allcentered mb-3" style={{ width: '60px', height: '60px', objectFit: 'contain' }}>
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/domainicon.png'} />
                                    </div>
                                </div>
                                <div class="col-lg-12 allcentered mb-3">
                                    <p class="m-0 p-0 poppinssemibold font-18" style={{ fontWeight: langdetect == 'en' ? '' : 600 }}>
                                        {lang.getyourdomain}
                                    </p>
                                </div>
                                <div class="col-lg-12 allcentered">
                                    <p class="m-0 p-0 text-light poppinslight text-center" style={{ fontSize: langdetect == 'en' ? '15px' : '17px' }}>
                                        {lang.getyourdomaindescription}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 d-flex align-items-stretch justify-content-center mb-5 p-0 d-md-none" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <img
                            src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/arrow.png'}
                            style={{ maxWidth: '100%', maxHeight: '100%', transform: langdetect == 'en' ? 'scale(1)' : 'scale(-1)', objectFit: 'contain' }}
                        />
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 d-flex align-items-stretch justify-content-center mb-5" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <div class={`${generalstyles.card}` + ' w-100 p-4 '}>
                            <div class="row m-0 w-100">
                                <div class="col-lg-12 allcentered mb-3">
                                    <div class="allcentered mb-3" style={{ width: '60px', height: '60px', objectFit: 'contain' }}>
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/bestprice.jpg'} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                    </div>
                                </div>
                                <div class="col-lg-12 allcentered mb-3">
                                    <p class="m-0 p-0 poppinssemibold font-18" style={{ fontWeight: langdetect == 'en' ? '' : 600 }}>
                                        {lang.competitiveprices}
                                    </p>
                                </div>
                                <div class="col-lg-12 allcentered">
                                    <p class="m-0 p-0 text-light poppinslight text-center" style={{ fontSize: langdetect == 'en' ? '15px' : '17px' }}>
                                        {lang.competitivepricesdescription}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
    const Sectionfour = () => {
        return (
            <section class={' col-lg-12 p-0 pt-2 '} style={{ zIndex: 2 }}>
                {/* <div class="row m-0 w-100 mb-5">
                    <div class="col-lg-12 d-flex align-items-center justify-content-center mb-5 mb-md-3" data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-mirror="true">
                        <p class={generalstyles.sectionTitle + ' p-0 m-0 text-center '} style={{ fontWeight: langdetect == 'en' ? '' : 700 }}>
                            {lang.alwaysdelivermorethanexpected}
                        </p>
                    </div>
                    <div class={'col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 mb-md-0 d-flex pl-5 pr-5 p-md-0'}>
                        <div class={' row m-0 w-100 allcentered pl-5 pr-5 p-md-0'}>
                            <div class={'col-lg-6 d-flex d-md-none justify-content-center align-items-stretch'} data-aos="fade-left" data-aos-easing="ease-in-out">
                                <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/image1.png'} style={{ maxWidth: '80%', maxHeight: '80%' }} />
                            </div>
                            <div class={'col-lg-6 d-flex justify-content-center h-100  p-md-0 pt-4'} data-aos="fade-right" data-aos-easing="ease-in-out">
                                <div class={' row ml-0 mr-0 w-100 allcentered mb-auto pl-5 pr-5 mt-5 '}>
                                    <div class="col-lg-12 d-flex justify-content-center justify-content-md-center mb-2">
                                        <p class={'poppinsmedium m-0 p-0 mb-2'} style={{ fontSize: '25px', fontWeight: langdetect == 'en' ? '' : 600 }}>
                                            {lang.mobileapp}
                                        </p>
                                    </div>
                                    <div class="col-lg-12 d-flex justify-content-center">
                                        <p class="p-0 m-0 text-info text-center poppinslight" style={{ fontSize: langdetect == 'en' ? '17px' : '19px' }}>
                                            {langdetect == 'en'
                                                ? "Let's give your trustworthy clients a full service by offering them an application with your identity."
                                                : 'يالا نقدم لعملائك الجديرين بالثقة خدمة كاملة من خلال تقديم تطبيق هاتف بهويتك لهم.'}
                                            <br />
                                            {langdetect == 'en' ? 'You can create and customize it any way you want!' : 'تقدر تتحكم فيه زى ما تحب! '}
                                        </p>
                                    </div>
                                    <div class="col-md-6 col-sm-12 d-none d-md-flex justify-content-center p-0 mt-4">
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/image1.png'} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class={'col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex pl-5 pr-5 mt-5 mt-md-0 p-md-0'}>
                        <div class={' row m-0 w-100 allcentered pl-5 pr-5 p-md-0'}>
                            <div class={'col-lg-6 col-md-12 d-flex justify-content-start h-100 h-md-auto'}>
                                <div class={' row ml-0 mr-0 w-100 allcentered mb-auto mt-5'}>
                                    <div class="col-lg-12 d-flex justify-content-center mb-2 pt-3" data-aos="fade-right" data-aos-easing="ease-in-out">
                                        <p class={'poppinsmedium m-0 p-0 mb-2'} style={{ fontSize: '25px', fontWeight: langdetect == 'en' ? '' : 600 }}>
                                            {lang.onlinestore}
                                        </p>
                                    </div>
                                    <div class="col-lg-12 d-flex justify-content-center" data-aos="fade-right" data-aos-easing="ease-in-out">
                                        <p class="p-0 m-0 text-info text-center poppinslight" style={{ fontSize: langdetect == 'en' ? '17px' : '19px' }}>
                                            {langdetect == 'en' ? 'Make your website within minutes;' : 'صمم متجرك إلكترونى خلال دقائق؛'}
                                            <br />
                                            {langdetect == 'en'
                                                ? 'Choose one of our pre-designed templates with your store name, logo, colors, products, ....'
                                                : 'إختار تصميم من تصميماتنا بإسمك، علامتك التجارية، الالوان اللى نحبهاو منتجاتك،ززز '}
                                            <br />
                                            {langdetect == 'en' ? 'or you can design your own template with the creativity of your own according to your rules!' : 'او نفذ تصميمك الخاص زى ما تحبه'}
                                        </p>
                                    </div>
                                    <div class="col-md-6 col-sm-12 mt-md-3 d-none d-md-flex justify-content-center p-md-0">
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/image2.png'} style={{ maxWidth: '80%', maxHeight: '80%' }} />
                                    </div>
                                </div>
                            </div>
                            <div class={'col-lg-6 col-md-12 d-flex d-md-none justify-content-center pl-0 pr-0 p-md-0'} data-aos="fade-right" data-aos-easing="ease-in-out">
                                <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/image2.png'} style={{ maxWidth: '80%', maxHeight: '80%' }} />
                            </div>
                        </div>
                    </div>
                    <div class={'col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4 mt-3 d-flex pl-5 pr-5 mt-5'}>
                        <div class={' row m-0 w-100 allcentered pl-5 pr-5 p-md-0'}>
                            <div class="col-lg-6 d-flex d-md-none justify-content-center pl-5 pr-0" data-aos="fade-left" data-aos-easing="ease-in-out">
                                <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/image3.png'} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                            </div>
                            <div class="col-lg-6 d-flex justify-content-center h-100 pl-5 pl-0 p-md-0 pt-5" data-aos="fade-right" data-aos-easing="ease-in-out">
                                <div class={' row ml-0 mr-0 w-100 allcentered mb-auto pt-2'}>
                                    <div class="col-lg-12 d-flex justify-content-center mb-2">
                                        <p class={'poppinsmedium m-0 p-0 mb-2'} style={{ fontSize: '25px', fontWeight: langdetect == 'en' ? '' : 600 }}>
                                            {lang.detailedreport}
                                        </p>
                                    </div>
                                    <div class="col-lg-12 d-flex justify-content-center">
                                        <p class="p-0 m-0 text-info text-center poppinslight" style={{ fontSize: langdetect == 'en' ? '17px' : '19px' }}>
                                            {langdetect == 'en' ? ' Get the right insights you need to scale up your business.' : 'احصل على كل التقارير اللى هتحتاجها عشان تكبر مجال عملك'}
                                            <br />{' '}
                                            {langdetect == 'en'
                                                ? 'We offer you different extenseions to sell more products to your customers and to increase your traffic'
                                                : 'بنقدملك ملحقات مختلفة عشان تقدر تبيع اكتر و توصل لناس اكتر'}
                                        </p>
                                    </div>
                                    <div class="col-md-6 col-sm-12 d-none d-md-flex justify-content-center p-0 mt-3">
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/image3.png'} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        );
    };
    const Sectionfive = () => {
        return (
            <section class={' col-lg-12 pt-5 pb-5 pl-md-3 pr-md-3 '} style={{ paddingLeft: '150px', paddingRight: '150px' }}>
                {/* <div class="row w-100 m-0 d-flex align-items-center justify-content-center mb-5" data-aos="fade-up" data-aos-easing="ease-in-out">
                    <div class="col-lg-12 d-flex align-items-center justify-content-center">
                        <p class={generalstyles.sectionTitle + ' p-0 m-0 text-md-center '} style={{ fontWeight: langdetect == 'en' ? '' : 700 }}>
                            {lang.customizeyouronlinestore}
                        </p>
                    </div>
                </div>
                <div class="row m-0 w-100 pb-3">
                    <div class="col-lg-4 col-md-10 m-auto mb-md-3 d-none d-md-flex align-items-stretch justify-content-center" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-delay="300">
                        <div class={homestyles.sectionthreeimagecard}>
                            <Lottie options={customizelottieoptions} style={{ cursor: 'default' }} />
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 d-flex align-items-stretch">
                        <div class="row m-0 w-100">
                            <div class="col-lg-12 p-0 mb-md-3" data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-delay="400">
                                <div class={homestyles.card + ' d-flex align-items-center justify-content-center '}>
                                    <div class="row m-0 w-100">
                                        <div class="col-lg-12 mb-2 p-0">
                                            <div class={`${homestyles.sectionthreeiconcont}` + ' bg-danger '}>
                                                <i class="fa fa-globe"></i>
                                            </div>
                                        </div>
                                        <div class="row m-0 w-100 d-flex justify-content-start">
                                            <div class="col-lg-12 d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-danger poppinsmedium p-0 m-0 wordbreak '}
                                                    style={{ fontWeight: langdetect == 'en' ? '' : 600, fontSize: langdetect == 'en' ? '17px' : '18px' }}
                                                >
                                                    {lang.customizeyourpresence}
                                                </p>
                                            </div>
                                            <div class="col-lg-12 d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-light p-0 m-0 wordbreak poppinslight '}
                                                    style={{ textAlign: langdetect == 'en' ? 'left' : 'right', fontSize: langdetect == 'en' ? '15px' : '17px' }}
                                                >
                                                    {lang.customizeyourpresencedescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 p-0 mb-md-3" data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-delay="500">
                                <div class={homestyles.card + ' d-flex align-items-center justify-content-center '}>
                                    <div class="row m-0 w-100">
                                        <div class="col-lg-12 mb-2 p-0">
                                            <div class={`${homestyles.sectionthreeiconcont}` + ' bg-aquagreen '}>
                                                <i class="h-100 d-flex align-items-center justify-content-center">
                                                    <MdGTranslate size={20} />
                                                </i>
                                            </div>
                                        </div>
                                        <div class="row m-0 w-100 d-flex justify-content-start">
                                            <div class="col-lg-12 d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-aquagreen poppinsmedium p-0 m-0  wordbreak '}
                                                    style={{ fontWeight: langdetect == 'en' ? '' : 600, fontSize: langdetect == 'en' ? '17px' : '18px' }}
                                                >
                                                    {lang.bilingualinterface}
                                                </p>
                                            </div>
                                            <div class="col-lg-12 d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-light p-0 m-0 wordbreak poppinslight '}
                                                    style={{ textAlign: langdetect == 'en' ? 'left' : 'right', fontSize: langdetect == 'en' ? '15px' : '17px' }}
                                                >
                                                    {lang.bilingualinterfacedescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 p-0 mb-md-3" data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-delay="600">
                                <div class={homestyles.card + ' d-flex align-items-center justify-content-center '}>
                                    <div class="row m-0 w-100">
                                        <div class="col-lg-12 mb-2 p-0">
                                            <div class={`${homestyles.sectionthreeiconcont}` + ' bg-teal '}>
                                                <i class="h-100 d-flex align-items-center justify-content-center">
                                                    <SiGoogleanalytics />
                                                </i>
                                            </div>
                                        </div>
                                        <div class="row m-0 w-100 d-flex justify-content-start">
                                            <div class="col-lg-12 d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-teal poppinsmedium p-0 m-0 wordbreak '}
                                                    style={{ fontWeight: langdetect == 'en' ? '' : 600, fontSize: langdetect == 'en' ? '17px' : '18px' }}
                                                >
                                                    {lang.analyticstools}
                                                </p>
                                            </div>

                                            <div class="col-lg-12 d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-light p-0 m-0 wordbreak poppinslight '}
                                                    style={{ textAlign: langdetect == 'en' ? 'left' : 'right', fontSize: langdetect == 'en' ? '15px' : '17px' }}
                                                >
                                                    {lang.analyticstoolsdescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 d-flex d-md-none align-items-stretch justify-content-center" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-delay="300">
                        <div class={homestyles.sectionthreeimagecard}>
                            <Lottie options={customizelottieoptions} height={600} width={600} style={{ cursor: 'default' }} />
                        </div>
                    </div>
                    <div class="col-lg-4 d-flex align-items-stretch">
                        <div class="row m-0 w-100">
                            <div class="col-lg-12 p-0 mb-md-3" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-delay="400">
                                <div class={homestyles.card + ' d-flex align-items-center justify-content-center '}>
                                    <div class={langdetect == 'en' ? 'row m-0 w-100 pl-5 p-md-0' : 'row m-0 w-100 pr-5 p-md-0'}>
                                        <div class="col-lg-12 mb-2 p-0">
                                            <div class={`${homestyles.sectionthreeiconcont}` + ' bg-mauve '}>
                                                <i class="h-100 d-flex align-items-center justify-content-center">
                                                    <AiOutlineBarChart size={20} />
                                                </i>
                                            </div>
                                        </div>
                                        <div class="row m-0 w-100 d-flex justify-content-start">
                                            <div class="col-lg-12 d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-mauve poppinsmedium p-0 m-0 wordbreak '}
                                                    style={{ fontWeight: langdetect == 'en' ? '' : 600, fontSize: langdetect == 'en' ? '17px' : '18px' }}
                                                >
                                                    {lang.salesreport}
                                                </p>
                                            </div>
                                            <div class="col-lg-12  d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-light p-0 m-0 wordbreak poppinslight '}
                                                    style={{ textAlign: langdetect == 'en' ? 'left' : 'right', fontSize: langdetect == 'en' ? '15px' : '17px' }}
                                                >
                                                    {lang.salesreportdescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 p-0 mb-md-3" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-delay="500">
                                <div class={homestyles.card + ' d-flex align-items-center justify-content-center '}>
                                    <div class={langdetect == 'en' ? 'row m-0 w-100 pl-5 p-md-0' : 'row m-0 w-100 pr-5 p-md-0'}>
                                        <div class="col-lg-12 mb-2 p-0 ">
                                            <div class={`${homestyles.sectionthreeiconcont}` + ' bg-hotpink '}>
                                                <i class="h-100 d-flex align-items-center justify-content-center">
                                                    <FaClipboardCheck size={20} />
                                                </i>
                                            </div>
                                        </div>
                                        <div class="row m-0 w-100 d-flex justify-content-start">
                                            <div class="col-lg-12  d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-hotpink poppinsmedium p-0 m-0 wordbreak '}
                                                    style={{ fontWeight: langdetect == 'en' ? '' : 600, fontSize: langdetect == 'en' ? '17px' : '18px' }}
                                                >
                                                    {lang.stockmanagement}
                                                </p>
                                            </div>
                                            <div class="col-lg-12  d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-light p-0 m-0 wordbreak poppinslight '}
                                                    style={{ textAlign: langdetect == 'en' ? 'left' : 'right', fontSize: langdetect == 'en' ? '15px' : '17px' }}
                                                >
                                                    {lang.stockmanagementdescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 p-0 mb-md-3 " data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-delay="600">
                                <div class={homestyles.card + ' d-flex align-items-center justify-content-center '}>
                                    <div class={langdetect == 'en' ? 'row m-0 w-100 pl-5 p-md-0' : 'row m-0 w-100 pr-5 p-md-0'}>
                                        <div class="col-lg-12 mb-2 p-0">
                                            <div class={`${homestyles.sectionthreeiconcont}` + ' bg-info '}>
                                                <i class="h-100 d-flex align-items-center justify-content-center">
                                                    <BsCreditCard size={20} />
                                                </i>
                                            </div>
                                        </div>
                                        <div class="row m-0 w-100">
                                            <div class="col-lg-12  d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-info poppinsmedium p-0 m-0 wordbreak '}
                                                    style={{ fontWeight: langdetect == 'en' ? '' : 600, fontSize: langdetect == 'en' ? '17px' : '18px' }}
                                                >
                                                    {lang.allpaymentsavailable}
                                                </p>
                                            </div>
                                            <div class="col-lg-12  d-flex justify-content-start p-0">
                                                <p
                                                    class={'text-light p-0 m-0 wordbreak poppinslight '}
                                                    style={{ textAlign: langdetect == 'en' ? 'left' : 'right', fontSize: langdetect == 'en' ? '15px' : '17px' }}
                                                >
                                                    {lang.allpaymentsavailabledescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        );
    };
    const Sectionsix = () => {
        return (
            <section class={' col-lg-12 pt-5 pb-5 pl-5 pr-5 pl-md-3 pr-md-3 '}>
                <div class="row w-100 m-0 d-flex align-items-center justify-content-center mb-5" data-aos="fade-up" data-aos-easing="ease-in-out">
                    <div class="col-lg-12 d-flex align-items-center justify-content-center">
                        <p class={generalstyles.sectionTitle + ' p-0 m-0 text-md-center '} style={{ fontWeight: langdetect == 'en' ? '' : 700 }}>
                            {lang.choosewhatsuitsyou}
                        </p>
                    </div>
                    <br />
                    <br />
                    <div class="col-lg-12 d-flex align-items-center justify-content-center">
                        <p style={{ fontSize: '19px' }} class={' text-light m-0 text-center mt-3  '}>
                            Gold Package Prices
                        </p>
                    </div>

                    <div class="col-lg-12 d-flex align-items-center justify-content-center">
                        <p style={{ fontSize: '19px' }} class={' text-light m-0 text-center mt-3  '}>
                            Website 
                        </p>
                    </div>
                    <div class="col-lg-12 d-flex align-items-center justify-content-center">
                        <p style={{ fontSize: '13px' }} class={' text-light m-0 text-center mt-3  '}>
                            *Offers are for new subscribers only.
                        </p>
                    </div>
                </div>
                <div class="row m-0 w-100 p-md-0 justify-content-center align-items-center">
                    <div class="col-lg-12 allcentered">
                        <div class="row pt-4 pb-4 m-0 w-100 allcentered">
                            {packages.map(function (item, index) {
                                return (
                                    <div class="col-lg-3 col-md-6 col-sm-12 mb-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                        <div
                                            style={{
                                                // boxShadow: '0px 0 30px rgba(1, 41, 112, 0.08)',
                                                borderRadius: '17px',
                                                // backgroundColor: index != 1 ? 'rgb(250,250,250)' : 'var(--primary)',
                                                color: index != 1 ? 'var(--primary)' : 'white',
                                                border: index != 1 ? '1px solid lightgray' : '4px solid var(--secondary)',
                                            }}
                                            class="p-2"
                                        >
                                            <div class="row m-0 w-100">
                                                <div
                                                    style={{
                                                        borderTopLeftRadius: '15px',
                                                        borderTopRightRadius: '15px',
                                                        backgroundColor: index != 1 ? 'rgba(204, 195, 53, 0.1)' : 'var(--secondary)',
                                                    }}
                                                    class="col-lg-12 pt-3 pb-3 d-flex justify-content-start justify-content-md-center"
                                                >
                                                    <div class="row m-0 w-100">
                                                        <p class="m-0 p-0 col-lg-12 poppinsbold mb-2 mt-3" style={{ fontSize: '24px', textAlign: 'start' }}>
                                                            {langdetect == 'en' ? item.name : item.namear}
                                                        </p>
                                                        <br />
                                                        <p
                                                            class="flex-row m-0 p-0 text-capitalize mb-4"
                                                            style={{ fontSize: '17px', color: index != 1 ? 'rgba(0,0,0,0.6)' : 'white', textAlign: 'start', display: 'flex', flexDirection: 'row' }}
                                                        >
                                                            ✨
                                                            <p class="p-0 m-0 mx-2" style={{ borderBottom: '1px solid ' }}>
                                                                {item.description}
                                                            </p>
                                                            ✨
                                                        </p>
                                                    </div>

                                                    {/* <p class="m-0 p-0 poppinsmedium   text-info">{lang.pricing}</p> */}
                                                </div>
                                                <div class="col-lg-12 pl-4 pr-4 d-flex justify-content-start mb-3 mt-5 text-primary">
                                                    <div class={`${homestyles.pricingcard_price}` + ' poppinsbold row '}>
                                                        <p class={homestyles.pricingcard_price + ' mr-1 poppinsbold p-0'}>{langdetect == 'en' ? 'EGP' : ''}</p>
                                                        {item.pricepermonth}
                                                        <p class={homestyles.pricingcard_price + ' mr-1 poppinsbold p-0'}>{langdetect == 'en' ? '' : 'ج.م'}</p>

                                                        {item.pricepermonth != '5,980' && (
                                                            <div
                                                                class="flex-row mr-1 ml-1 poppinslight "
                                                                style={{ textDecoration: 'line-through', fontWeight: 400, fontSize: '18px', marginTop: '5px', opacity: 0.7 }}
                                                            >
                                                                {langdetect == 'en' ? 'EGP ' : ''}
                                                                {item.priceperquarter}
                                                                {langdetect == 'en' ? '' : ' ج.م'}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* <div class="col-lg-12 allcentered mt-4 mb-3">
                                                    <div class="col-lg-10 col-md-6 col-sm-12 allcentered">
                                                        <img src={item.image} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                    </div>
                                                </div> */}
                                                <div class="col-lg-12 d-flex justify-content-start justify-content-md-center mt-3 mb-4">
                                                    <button
                                                        style={{
                                                            width: '100%',
                                                            border: '1px solid var(--secondary)',
                                                            color: 'var(--secondary)',
                                                        }}
                                                        class={
                                                            index != 1
                                                                ? `${homestyles.generalhomebutton}` + '  d-flex justify-content-center align-items-center '
                                                                : `${homestyles.generalhomebutton}` + ' bg-secondary bg-secondaryhover text-white d-flex justify-content-center align-items-center '
                                                        }
                                                        onClick={() => {
                                                            history.push('/home/pricing');
                                                        }}
                                                    >
                                                        {lang.features}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    };
    const Sectionseven = () => {
        return (
            <section
                // style={{
                //     background: 'linear-gradient(var(--primary),#3fa6d3)',
                // }}
                class={' col-lg-12 pt-5 pb-5 pl-5 pr-5 pl-md-3 pr-md-3 '}
            >
                <div class={homestyles.container + ' row w-100 m-0 d-flex align-items-center justify-content-center mb-5'} data-aos="fade-up" data-aos-easing="ease-in-out">
                    <div class="col-lg-12 ">
                        <div class="row m-0 allcentered w-100">
                            <div class={' col-lg-6 col-md-12 allcentered'}>
                                <div class="row m-0 w-100 allcenetered mt-4 ">
                                    <div
                                        class="col-lg-10 p-0 poppinsbold d-flex justify-content-start justify-content-md-center text-md-center mb-4 mb-md-2"
                                        style={{ fontSize: '25px', textAlign: 'start', fontWeight: 600 }}
                                    >
                                        {lang.morethanthousandcustomerstrustus} {lang.foreaseofusevarietyandcustomerserviceavailablehoursaday}
                                    </div>
                                    <div
                                        class="col-lg-10 d-flex justify-content-start justify-content-md-center text-md-center p-0"
                                        style={{
                                            color: 'rgb(47, 47, 47)',
                                            fontSize: 'clamp(14px, 0.875rem + (1vw - 3.2px) * 0.476, 17px)',
                                            whiteSpace: 'pre-wrap',
                                            minEidth: '1px',
                                            textAlign: 'start',
                                        }}
                                    >
                                        {lang.Morethanpredesignedtemplatessoyoucanmodifythemasyoulikewithouteffort}
                                    </div>
                                    <div class="col-lg-10 d-flex justify-content-start justify-content-md-center mt-5 mt-md-2 mb-md-3 mt-sm-5 mb-sm-5 p-0">
                                        <button
                                            style={{
                                                width: '55%',
                                                height: '45px',
                                                fontWeight: 600,
                                            }}
                                            class={`${homestyles.generalhomebutton2}` + '  d-flex justify-content-center align-items-center  '}
                                            onClick={() => {
                                                // history.push('/home/pricing');
                                                document.querySelector(`#Form`).scrollIntoView({ behavior: 'smooth' });
                                            }}
                                        >
                                            {lang.startnow}
                                        </button>
                                    </div>
                                </div>
                            </div>{' '}
                            <div class="col-lg-5 p-0 mb-md-4 ">
                                <div class="row m-0 w-100 mt-4 mb-4 m-md-0">
                                    <img
                                        src={serverbaselinkimgcontext + '/tr:w-1400,h-1000' + '/tabexserieswebsiteasset/7.jpg'}
                                        class={homestyles.container}
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 w-100 pl-5 pr-5 p-md-0 justify-content-center align-items-center"></div>
            </section>
        );
    };
    const Sectioneight = () => {
        return (
            <section id={'Form'} class={' col-lg-12 pt-5 pb-5 pl-5 pr-5 pl-md-3 pr-md-3 '}>
                <div class="row w-100 m-0 d-flex align-items-center justify-content-center mb-5" data-aos="fade-up" data-aos-easing="ease-in-out">
                    <div class="col-lg-12 ">
                        <div class="row m-0 d-flex justify-content-center w-100">
                            <div class="col-lg-6 p-0 mb-md-4 ">
                                <div class="row m-0 w-100 mt-4 m-md-0">
                                    <div class="col-lg-10 p-0   poppinsbold mb-4 mb-md-2" style={{ fontSize: '32px', textAlign: 'start', fontWeight: 600 }}>
                                        {lang.getintouch}
                                    </div>
                                    <div class="col-lg-10 p-0" style={{ fontSize: '19px', textAlign: 'start', color: 'rgb(47, 47, 47)' }}>
                                        {lang.doyouwanttolearnmoreaboutwhatwedogetintouchandwellcontactyoushortly}
                                        <br />
                                        {lang.doyouwanttolearnmoreaboutwhatwedogetintouchandwellcontactyoushortly2}
                                    </div>
                                    <div class="col-lg-12 p-0 d-flex d-md-none mt-5">
                                        <img
                                            src={serverbaselinkimgcontext + '/tr:w-800,h-450' + '/tabexserieswebsiteasset/signupasset2.png'}
                                            style={{ width: '85%', height: '85%', objectFit: 'contain' }}
                                            class="d-flex d-sm-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ boxShadow: '0px 0 30px rgba(1, 41, 112, 0.08)', borderRadius: '4px' }} class={' col-lg-5 col-md-12'}>
                                {!contactusmessagesent && (
                                    <div class="row m-0 p-3 p-md-1 pt-md-2 w-100">
                                        {attr?.map((item, index) => {
                                            var show = false;
                                            alertattr.map((sub, subindex) => {
                                                if (sub == item.attr) {
                                                    show = true;
                                                }
                                            });
                                            return (
                                                <>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 mb-2 d-flex justify-content-start" id={item.attr}>
                                                        <div class="row m-0 w-100  ">
                                                            <div class={`${formstyles.form__group} ${formstyles.field}`}>
                                                                <label class={formstyles.form__label}>{item.name}</label>
                                                                <input
                                                                    type={'text'}
                                                                    class={formstyles.form__field}
                                                                    value={payload[item.attr]}
                                                                    onChange={(event) => {
                                                                        var temp = { ...payload };
                                                                        var newvalue = event.target.value;
                                                                        if (item.attr == 'phonenumber') {
                                                                            newvalue = newvalue.replace(/[^\d]/g, '');
                                                                        } else {
                                                                        }
                                                                        temp[item.attr] = newvalue;
                                                                        setpayload({ ...temp });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{ fontSize: '12px', opacity: show ? '1' : '0', height: show ? '' : '0px', transition: 'all 0.2s', textAlign: 'start' }}
                                                        class="col-lg-12 pl-3 pr-3 text-danger"
                                                    >
                                                        {lang.invalid} {item.name}
                                                    </div>
                                                </>
                                            );
                                        })}
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-2 d-flex justify-content-start">
                                            <div class="row m-0 w-100  ">
                                                <div class={`${formstyles.form__group} ${formstyles.field}`}>
                                                    <label class={formstyles.form__label}>{lang.companysize}</label>
                                                    <Select
                                                        placeholder={''}
                                                        options={[
                                                            { label: lang.startup, value: 'startup' },
                                                            { label: lang.small, value: 'small' },
                                                            { label: lang.large, value: 'large' },
                                                        ]}
                                                        styles={defaultstyles}
                                                        value={[
                                                            { label: lang.startup, value: 'startup' },
                                                            { label: lang.small, value: 'small' },
                                                            { label: lang.large, value: 'large' },
                                                        ].filter((option) => option.value == payload.companysize)}
                                                        onChange={(option) => {
                                                            setpayload({ ...payload, companysize: option.value });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-2 d-flex justify-content-start">
                                            <div class="row m-0 w-100  ">
                                                <div class={`${formstyles.form__group} ${formstyles.field}`}>
                                                    <label class={formstyles.form__label}>{lang.packages}</label>
                                                    <Select
                                                        placeholder={''}
                                                        options={[
                                                            { label: lang.website, value: 'website' },
                                                            { label: lang.webandmob, value: 'websiteandmobile' },
                                                        ]}
                                                        styles={defaultstyles}
                                                        value={[
                                                            { label: lang.website, value: 'website' },
                                                            { label: lang.webandmob, value: 'websiteandmobile' },
                                                        ].filter((option) => option.value == payload.package)}
                                                        onChange={(option) => {
                                                            setpayload({ ...payload, package: option.value });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-2 d-flex justify-content-start">
                                            <div class="row m-0 w-100  ">
                                                <div class={`${formstyles.form__group} ${formstyles.field}`}>
                                                    <label class={formstyles.form__label}>{lang.notes}</label>
                                                    <TextareaAutosize
                                                        minRows={3}
                                                        maxRows={3}
                                                        class={formstyles.form__field}
                                                        value={payload.notes}
                                                        onChange={(event) => {
                                                            var temp = { ...payload };
                                                            temp.notes = event.target.value;
                                                            setpayload({ ...temp });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 mb-4 mt-4 mt-md-3 d-flex justify-content-start">
                                            <div class="row allcentered m-0 w-100  ">
                                                <button
                                                    style={{
                                                        width: '140px',
                                                    }}
                                                    class={`${homestyles.generalhomebutton}` + ' bg-primary bg-secondaryhover text-white d-flex justify-content-center align-items-center '}
                                                    onClick={() => {
                                                        var exist = false;
                                                        var attribue = '';
                                                        var temp = [];

                                                        attr.slice(0)
                                                            .reverse()
                                                            .map((item, index) => {
                                                                attribue = item.attr;

                                                                if (item.attr == 'name') {
                                                                    if (payload[item.attr]?.length >= 3) {
                                                                        // alert('ll');
                                                                        // setalertattr('');
                                                                    } else {
                                                                        // setalertattr(item.attr);
                                                                        temp.push(item.attr);
                                                                        exist = true;
                                                                    }
                                                                } else if (item.attr == 'phonenumber') {
                                                                    if (payload[item.attr]?.length >= 10) {
                                                                        // alert('ll');
                                                                        // setalertattr('');
                                                                    } else {
                                                                        // setalertattr(item.attr);
                                                                        temp.push(item.attr);
                                                                        exist = true;
                                                                    }
                                                                } else {
                                                                    if (payload[item.attr]?.length != 0) {
                                                                        // alert('ll');
                                                                        // setalertattr('');
                                                                    } else {
                                                                        // setalertattr(item.attr);
                                                                        temp.push(item.attr);
                                                                        exist = true;
                                                                    }
                                                                }
                                                            });
                                                        if (exist) {
                                                            if (attribue == 'name') {
                                                                document.querySelector(`#name`).scrollIntoView({ behavior: 'smooth' });
                                                            } else if (attribue == 'phonenumber') {
                                                                document.querySelector(`#phonenumber`).scrollIntoView({ behavior: 'smooth' });
                                                            } else if (attribue == 'industry') {
                                                                document.querySelector(`#industry`).scrollIntoView({ behavior: 'smooth' });
                                                            }
                                                        } else {
                                                            var temppayload = { ...payload };
                                                            var platformads = queryParameters.get('plat');
                                                            temppayload.platform = platformads;
                                                            submittabexwebsite_contactusformMutation.mutate(temppayload);
                                                        }
                                                        setalertattr([...temp]);
                                                        // submittabexwebsite_contactusformMutation.mutate(payload)
                                                        // window.fbq('track', 'Lead', { name: payload.name });
                                                    }}
                                                    disabled={submittabexwebsite_contactusformMutation.isLoading}
                                                >
                                                    {!submittabexwebsite_contactusformMutation.isLoading && <p> {lang.submit}</p>}
                                                    <div
                                                        class={
                                                            submittabexwebsite_contactusformMutation.isLoading
                                                                ? generalstyles.fadeInButton + ' d-flex align-items-center justify-content-center '
                                                                : generalstyles.fadeOutButton
                                                        }
                                                    >
                                                        <CircularProgress color="#fff" width="30px" height="30px" duration="1s" />
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {contactusmessagesent && (
                                    <div class="col-lg-12 col-md-12 col-sm-12 allcentered">
                                        <div class="row m-0 w-100">
                                            <div class="col-lg-12 allcentered">
                                                <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/messagesent.png'} style={{ maxWidth: '70%' }} />
                                            </div>
                                            <div class="col-lg-12 allcentered">
                                                <p class="mt-4 mb-0 p-0 poppinsitalic text-info" style={{ fontSize: '18px' }}>
                                                    {langdetect == 'en' ? 'We received your messge' : 'رسالتك وصلتلنا'}
                                                </p>
                                            </div>
                                            <div class="col-lg-12 allcentered">
                                                <p class="m-0 p-0 poppinsitalic text-info" style={{ fontSize: '18px' }}>
                                                    {langdetect == 'en' ? '& we will contact you soon' : 'و هنكلمك فى اسرع وقت'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 w-100 pl-5 pr-5 p-md-0 justify-content-center align-items-center"></div>
            </section>
        );
    };
    const Sectionnine = () => {
        return (
            <section
                // style={{
                //     background: 'linear-gradient(var(--primary),#3fa6d3)',
                // }}
                class={' col-lg-12 pt-5 pb-5 pl-4 pr-4 pl-md-3 pr-md-3 '}
            >
                <div class="row w-100 m-0 d-flex align-items-center justify-content-center mb-5" data-aos="fade-up" data-aos-easing="ease-in-out">
                    {/* <div class="col-lg-12 d-flex align-items-center justify-content-center">
                        <p class={generalstyles.sectionTitle + ' p-0 m-0 text-md-center mb-3 '} style={{ fontWeight: langdetect == 'en' ? '' : 700, color: 'White' }}>
                            Form
                        </p>
                    </div> */}
                    <div class="col-lg-12 ">
                        <div class="row m-0 d-flex justify-content-center w-100">
                            <div class="col-lg-6 mb-md-4  ">
                                <div class="row m-0 w-100 mt-4 m-md-0">
                                    <div class="col-lg-12 p-0   poppinsbold mb-1 mb-md-2" style={{ fontSize: '32px', textAlign: 'center', fontWeight: 600 }}>
                                        {lang.bedifferent}
                                    </div>
                                    <div class="col-lg-12 p-0 text-secondary mb-2" style={{ fontSize: '19px', textAlign: 'center', color: 'rgb(47, 47, 47)' }}>
                                        {lang.choosefromourdifferentpredesignedtemplatesandsections}
                                    </div>
                                    <div class="col-lg-12 d-md-none mt-4 p-0">
                                        <img src={serverbaselinkimgcontext + '/tr:w-600,h-200' + '/tabexserieswebsiteasset/1.png'} style={{ width: '100%', objectFit: 'contain' }} />
                                    </div>
                                    <div class="col-lg-12 d-md-none p-0">
                                        <img src={serverbaselinkimgcontext + '/tr:w-600,h-200' + '/tabexserieswebsiteasset/4.png'} style={{ width: '100%', objectFit: 'contain' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-md-4 d-md-none ">
                                <div class="row m-0 w-100 mt-0 m-md-0">
                                    <div class="col-lg-12 p-0">
                                        <img src={serverbaselinkimgcontext + '/tr:w-600,h-200' + '/tabexserieswebsiteasset/2.png'} style={{ width: '100%', objectFit: 'contain' }} />
                                    </div>
                                    <div class="col-lg-12 p-0">
                                        <img src={serverbaselinkimgcontext + '/tr:w-600,h-200' + '/tabexserieswebsiteasset/3.png'} style={{ width: '100%', objectFit: 'contain' }} />
                                    </div>
                                    <div class="col-lg-4 mt-3 p-0">
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/5.webp'} style={{ width: '100%', height: '300px', objectFit: 'contain' }} />
                                    </div>
                                    <div class="col-lg-8 mt-3 allcentered text-secondary poppinsmedium p-0" style={{ fontSize: '28px', fontWeight: 500, textAlign: 'center' }}>
                                        {lang.choosewhatsuitsyoufromourtemplates}
                                    </div>{' '}
                                </div>
                            </div>
                            <div class=" mb-md-4 d-none d-md-flex ">
                                <div style={{ overflowY: 'scroll', flexWrap: 'nowrap', width: '44vh' }} class={' scrollmenuclasssubscrollbar row m-0  '}>
                                    {images?.map((item, index) => {
                                        return (
                                            <div style={{ width: '200px', height: '100px' }} class="mr-2">
                                                <img style={{ width: '200px', height: '100px', objectFit: 'contain' }} src={item} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 w-100 pl-5 pr-5 p-md-0 justify-content-center align-items-center"></div>
            </section>
        );
    };

    return (
        <div class="row m-0 w-100 " style={{ background: 'white' }}>
            {langdetect != undefined && (
                <>
                    <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/herobg.png'} style={{ position: 'absolute', top: 0 }} class="d-md-none" />

                    <div class="col-lg-12 p-0">
                        <div class=" row m-0 w-100 d-none d-md-flex" style={{ background: 'white' }}>
                            {Sectionone()}
                            {Sectionseven()}
                        </div>
                    </div>
                    <div class="col-lg-12 p-0">
                        <div class=" row m-0 w-100 d-flex d-md-none">
                            {Sectionone()}
                            {Sectionseven()}
                        </div>
                    </div>
                    {/* {Sectiontwo()} */}
                    {Sectionthree()}
                    {/* {Sectionfour()} */}
                    {/* {Sectionfive()} 
                    {Sectionnine()} */}
                    {Sectionsix()}
                    {Sectioneight()}
                    {/* <div style={{ position: 'fixed', bottom: 70, right: 15, zIndex: 1000000000 }}>
                <button
                    style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '100px',
                        background: 'linear-gradient(to right, var(--primary) , #00576d)',
                    }}
                    class=" bg-secondaryhover"
                    onClick={() => {
                        window.open(`https://web.whatsapp.com/send/?phone=$01277449150}`);
                    }}
                >
                    <i class="h-100 allcentered">
                        <AiOutlineWhatsApp color={'white'} size={20} />
                    </i>
                </button>
            </div> */}
                    <div style={{ position: 'fixed', bottom: 10, right: 15, zIndex: 1000000000 }}>
                        <button
                            style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '100px',
                                background: 'linear-gradient(to right, var(--primary) , #00576d)',
                            }}
                            class=" bg-secondaryhover"
                            onClick={() => {
                                window.open('https://api.whatsapp.com/send?phone=1277627572', '_blank');
                            }}
                        >
                            <i class="h-100 allcentered">
                                <AiOutlineWhatsApp color={'white'} size={20} />
                            </i>
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};
export default Home;
